<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="row">

          <div class="col-md-12">

            <div class="checkbox checkbox-primary">
              <input id="terms" type="checkbox" v-model="terms">
              <label for="terms" class="mb-1">I agree to the <span
                  class="text-primary">Terms and Conditions</span>.</label>
            </div>
            <small v-if="!terms" class="text-danger">You must agree!</small>
          </div>
          <div class="col-md-12">

          </div>
        </div>


        <Teleport v-if="isTargetAvailable" to="#global-sidebar-area">
          <div class="col-md-12 mt-2 mb-4 d-flex flex-column align-items-center justify-content-center">
            <h3 class="fw-bolder">Total: {{ totalPrice }}</h3>
            <div class="text-primary">{{ paymentStore.who_pays.toUpperCase() }} Pays</div>
          </div>
        </Teleport>



        <div class="col-md-12 mt-3 d-flex align-items-center justify-content-center">
          <button :disabled="!terms" @click="handleMakePayment" class="btn btn-primary btn-lg">Make Payment and
            Complete
          </button>
        </div>

      </div>
    </div>
  </div>
</template>

<script setup>
import {usePaymentStore} from "@/stores/paymentStore";
import {currencyExchange, priceFormatter, SwalMessage} from "@/core/services/general.service";
import {computed, onMounted, ref} from "vue";

const terms = ref(true);

const totalPrice = computed(() => {
  return priceFormatter(currencyExchange(paymentStore.amount_to_be_paid, paymentStore.original_currency).converted_price);
});

const paymentStore = usePaymentStore();


const emit = defineEmits(['make-payment']);


const handleMakePayment = () => {

  if (paymentStore.payment_method === 'credit') {

    if (!paymentStore.selected_payment_method){
      SwalMessage('error', 'Please select a payment method!', 'Error');
      return;
    }

    if (!paymentStore.checkCardInformation()) {
      SwalMessage('error', 'Please enter your card information.', 'Error');
      return;
    }

  }

  if (paymentStore.payment_method === 'current') {

    if (typeof paymentStore.selected_wallet === 'object' && paymentStore.selected_wallet !== null) {

      const walletCurrency = paymentStore.selected_wallet.currency_code;
      const amountToBePaidInWalletCurrency = currencyExchange(paymentStore.amount_to_be_paid, paymentStore.original_currency, walletCurrency).converted_price;

      if (amountToBePaidInWalletCurrency > paymentStore.selected_wallet.balance) {
        SwalMessage('error', `Your ${paymentStore.selected_wallet.currency_code} account balance is insufficient for this transaction. Please choose a different wallet account or top up balance.`, 'Insufficient Balance');
        return;
      }

    } else {
      SwalMessage('error', 'Please select a wallet account!', 'Error');
      return;
    }
  }

  emit('make-payment')
}

const isTargetAvailable = ref(false);

onMounted(() => {
  const targetElement = document.querySelector("#global-sidebar-area");
  isTargetAvailable.value = !!targetElement;
  terms.value = false;

});

</script>

<style scoped></style>
