<template>
  <div>

    <StepHeader step-number="2"
                step-title="Enter Your Card Info"
                step-description="Please enter your card information."
    />

    <div class="row d-flex align-items-center justify-content-center">
      <div class="col-md-8">

        <div v-if="paymentStore.paymentFailed" class="row my-3">
          <div class="col-md-12">
            <div class="alert alert-danger">
              <i class="fa fa-times-circle"></i>
              {{ paymentStore.paymentFailedMessage }}
            </div>
          </div>
        </div>

        <div v-if="!isProduction" class="row mb-4">
          <div class="col-md-12">
            <label for="test_cards">Test Cards:</label>
            <select @change="handleTestCardChange" name="test_cards" id="test_cards" class="form-control">
              <option value="">Select a test card</option>
              <option v-for="test_card in test_cards" :value="test_card.id">{{ test_card.card_name }}</option>
            </select>
          </div>
        </div>


        <div class="row">
          <div class="col-md-12">
            <DxTextBox
                v-model="paymentStore.credit_card.cardholder_name"
                label="Cardholder Name:"
                label-mode="outside"
                validationMessagePosition="left"
            >
              <DxValidator>
                <DxRequiredRule message="This field is required"/>
              </DxValidator>
            </DxTextBox>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-md-12 ">
            <DxTextBox
                v-model="paymentStore.credit_card.card_number"
                label="Card Number:"
                label-mode="outside"
                mask="0000 0000 0000 0000"
                validationMessagePosition="left"
            >
              <DxValidator>
                <DxRequiredRule message="This field is required"/>
              </DxValidator>
            </DxTextBox>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-6">
            <DxTextBox
                v-model="paymentStore.credit_card.expiration"
                label="MM/YY :"
                label-mode="outside"
                use-masked-value="true"
                mask="00/00"
                validationMessagePosition="left"
            >
              <DxValidator>
                <DxRequiredRule message="This field is required"/>
              </DxValidator>
            </DxTextBox>
          </div>
          <div class="col-6">
            <DxTextBox
                v-model="paymentStore.credit_card.security_code"
                label="CVC / CVV :"
                label-mode="outside"
                mask="000"
                validationMessagePosition="right"
            >
              <DxValidator>
                <DxRequiredRule message="This field is required"/>
              </DxValidator>
            </DxTextBox>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script setup>
import DxTextBox from 'devextreme-vue/text-box';
import "@/core/data/test_cards.json";
import {
  DxValidator, DxRequiredRule,
} from 'devextreme-vue/validator';
import {onMounted, ref, watch} from "vue";
import {usePaymentStore} from "@/stores/paymentStore";
import StepHeader from "@/components/General/StepHeader.vue";
import {isProduction} from "@/core/services/general.service";


const paymentStore = usePaymentStore();


const handleTestCardChange = (event) => {
  const test_card_id = parseInt(event.target.value);

  const test_card = test_cards.find(test_card => test_card.id === test_card_id);
  if (test_card) {
    paymentStore.credit_card.cardholder_name = test_card.holder_name;
    paymentStore.credit_card.card_number = test_card.card_number;
    paymentStore.credit_card.expiration = test_card.expire_date;
    paymentStore.credit_card.security_code = test_card.cvv;
  } else {
    paymentStore.credit_card.cardholder_name = "";
    paymentStore.credit_card.card_number = "";
    paymentStore.credit_card.expiration = "";
    paymentStore.credit_card.security_code = "";
  }
};

const test_cards = require("@/core/data/test_cards.json");


onMounted(() => {
  paymentStore.credit_card.cardholder_name = "";
  paymentStore.credit_card.card_number = "";
  paymentStore.credit_card.expiration = "";
  paymentStore.credit_card.security_code = "";
});

</script>

<style scoped>

</style>