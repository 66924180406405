import {computed, ref, toRaw} from "vue";
import {useLoading} from "vue-loading-overlay";
import CustomLoadingAnimation from "@/components/CustomLoadingAnimation.vue";
import 'vue-loading-overlay/dist/css/index.css';
import toastr from "toastr"
import Swal from 'sweetalert2';
import {$translate} from "@/core/services/translator.service";
import {useAuthStore} from "@/stores/authStore";
import moment from "moment-timezone";

export const isLoading = ref(false);

export const ToastMessage = (type, message, title) => {
    toastr[type](message, title);

    toastr.options = {
        "closeButton": false,
        "debug": false,
        "newestOnTop": false,
        "progressBar": false,
        "positionClass": "toast-top-right",
        "preventDuplicates": false,
        "onclick": null,
        "showDuration": "300",
        "hideDuration": "1000",
        "timeOut": "5000",
        "extendedTimeOut": "1000",
        "showEasing": "swing",
        "hideEasing": "linear",
        "showMethod": "fadeIn",
        "hideMethod": "fadeOut"
    };
};

const $loading = useLoading({
    loader: CustomLoadingAnimation
});

export const BlockContainer = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
        const loader = $loading.show({
            container: element, // Attach loader to the specific element
            canCancel: false,
            isFullPage: false,
        });

        return loader; // return loader to manually hide if needed
    } else {
        console.error(`Element with id ${elementId} not found.`);
    }
};

export const BlockFullPage = () => {
    const loader = $loading.show({
        canCancel: false,
        isFullPage: true,
    });

    return loader; // return loader to manually hide if needed
};

export const UnBlocker = (loader) => {
    if (loader) {
        loader.hide();
    } else {
        console.error('Loader instance is not provided.');
    }
};

export const SwalMessage = (type, message, title, canBeClosed = true) => {
    Swal.fire({
        icon: type,
        title: title,
        text: message,
        allowOutsideClick: canBeClosed,
        allowEscapeKey: canBeClosed,
    });
}

export const SwalLoading = (type, message, title) => {
    Swal.fire({
        title: title,
        text: message,
        icon: type,
        allowOutsideClick: false,
        didOpen: () => {
            Swal.showLoading();
        }
    });
}

export const SwalClose = () => {
    Swal.close();
}

export const ConfirmWithSwal = (
    type,
    message,
    title,
    ConfirmButtonText = $translate('common.swal_btn_countinue'),
    CancelButtonText = $translate('common.swal_btn_cancel'),
    ShowCancelMessage = true,
    AllowEscape = true,
) => {
    return new Promise((resolve) => {
        Swal.fire({
            icon: type,
            title: title,
            text: message,
            showCancelButton: true,
            confirmButtonText: ConfirmButtonText,
            cancelButtonText: CancelButtonText,
            customClass: {
                confirmButton: 'me-4 btn btn-primary',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false,
            allowOutsideClick: AllowEscape,
            allowEscapeKey: AllowEscape,
        }).then((result) => {
            if (result.isConfirmed) {
                resolve(true);
            } else {
                if (ShowCancelMessage)
                    ToastMessage(
                        'info',
                        $translate('common.confirm_swal_cancel_message'),
                        $translate('common.info')
                    );
                resolve(false);
            }
        });
    });
}

export const delay = (callback, ms) => {
    var timer = 0;
    return function () {
        var context = this, args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
            callback.apply(context, args);
        }, ms || 0);
    };
}

export const number_format = (number, decimals, thousands_sep, dec_point) => {
    number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
    var n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
        dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
        s = '',
        toFixedFix = function (n, prec) {
            var k = Math.pow(10, prec);
            return '' + Math.round(n * k) / k;
        };

    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
}

export const priceFormatter = (price, currency_code = null) => {

    return computed(() => {
        const authStore = useAuthStore();
        let currentCurrency = null;
        currentCurrency = authStore.currentCurrency;

        let currencies = Object.values(toRaw(authStore.currencies)); // Use toRaw for Proxy


        if (currency_code) {
            currentCurrency = currencies.find(currency => currency.code === currency_code);
        }

        if (!currentCurrency) return false;

        const formattedPrice = number_format(price, 2, '.', ',');

        if (currentCurrency.prefix)
            return currentCurrency.prefix + "" + formattedPrice;
        else
            return formattedPrice + "" + currentCurrency.suffix;
    }).value;
};


export const currencyExchange = (price, currency_code, exchange_to = null, rate_type = 'rate') => {

    // console.log("currencyExchange worked", {
    //     price,
    //     currency_code,
    //     exchange_to,
    //     rate_type
    // })


    currency_code = currency_code === 'TRY' ? 'TL' : currency_code;
    exchange_to = exchange_to === 'TRY' ? 'TL' : exchange_to;

    const authStore = useAuthStore();

    const allCurrencies = Object.values(toRaw(authStore.all_currencies));
    const currencies = Object.values(toRaw(authStore.currencies));

    // console.log("here is the all currencies", allCurrencies);
    // console.log("here is currenct currencies", authStore.currentCurrency);
    // console.log("here is currencies", currencies);

    const original_currency = allCurrencies.find(c => c.code === currency_code);

    if (!original_currency) {
        let errorMessage = `Original currency code '${currency_code}' not found!.`;
        ToastMessage('error', errorMessage, 'Error');
        throw new Error(errorMessage);
    }


    const currency_to_be_converted = exchange_to === null
        ? authStore.currentCurrency
        : allCurrencies.find(c => c.code === exchange_to);

    if (!currency_to_be_converted) {
        let errorMessage = `Target currency code '${exchange_to}' not found!.`;
        ToastMessage('error', errorMessage, 'Error');
        console.error(errorMessage);
        // debugger;
        throw new Error(errorMessage);
    }

    const rate = currency_to_be_converted[rate_type] / original_currency[rate_type];

    const convertedPrice = price * rate;

    const result = {
        original_price: price,
        original_currency: currency_code,
        converted_price: php_round(convertedPrice, 9),
        target_currency: currency_to_be_converted.code,
        rate_used: rate,
        rate_type: rate_type
    };

    // console.log("here is the result", result);
    return result;
};


const uniqueIdCounter = ref(0);

export function generateUniqueId(prefix = 'id') {
    uniqueIdCounter.value++;
    return `${prefix}-${uniqueIdCounter.value}-${Date.now()}`;
}

export const php_round = (num, dec) => {
    const num_sign = num >= 0 ? 1 : -1;
    return parseFloat((Math.round((num * Math.pow(10, dec)) + (num_sign * 0.0001)) / Math.pow(10, dec)).toFixed(dec));
}

export const globalMoment = (date, format = 'DD.MM.YYYY') => {
    const timezone = localStorage.getItem('timezone') || 'Europe/Istanbul';
    const language = localStorage.getItem('currentLanguage') || 'en';

    moment.locale(language);

    return moment.tz(date, timezone).format(format);
};

export const changeSystemCurrency = (currencyCode) => {

    const authStore = useAuthStore();
    const currencies = Object.values(toRaw(authStore.currencies));
    currencies.forEach((cur) => {
        cur.default = cur.code === currencyCode;
    });

    authStore.currencies = currencies;

    localStorage.setItem("currency", currencyCode)
};

export const isProduction = computed(() => {
    return process.env.NODE_ENV === 'production';
});