<template>

  <div class="container min-vh-100">
    <Breadcrumbs main="Test Credit Pay"/>

    <div class="row">

      <div class="col-md-8">
        <div class="row p-0 m-0">

          <div class="col-md-12 p-0">
            <PaymentInformation
                ref="paymentInformationRef"
                :current-payment-enabled="false"
                @make-payment="makePayment()"/>
          </div>

        </div>
      </div>

      <div class="col-md-4">
        <div class="sidebar">
          <div class="content-wrapper">
            <div class="row p-0 m-0">

              <div class="col-md-12 p-0 m-0">


                <div class="mt-3 p-0 m-0">
                  <SummeryInformation :price="1"/>
                </div>

              </div>

            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

  <PaymentHtmlPreviewModal ref="paymentHtmlPreviewModalRef"/>
  <!-- </div> -->
</template>


<script setup>
import {useRoute, useRouter} from "vue-router";
import {onMounted, ref, watch} from "vue";
import {useHotelStore} from "@/stores/Packages/hotelStore";
import {errorService} from "@/core/services/error.service";
import {
  BlockFullPage,
  UnBlocker
} from "@/core/services/general.service";
import {useAuthStore} from "@/stores/authStore";
import PaymentInformation
  from "@/components/PaymentInformation/PaymentInformation.vue";
import {usePaymentStore} from "@/stores/paymentStore";
import PaymentHtmlPreviewModal from "@/components/PaymentInformation/PreviewModal/PaymentHtmlPreviewModal.vue";
import SummeryInformation from "@/pages/payment/test_pay/credit/includes/SummeryInformation.vue";

const route = useRoute();
const token = route.params.token;

const hotelStore = useHotelStore();
const product = ref(null);

const authStore = useAuthStore();
const router = useRouter();

const paymentInformationRef = ref();
const paymentStore = usePaymentStore();
const paymentHtmlPreviewModalRef = ref();


const getTestPaymentData = async () => {

  const loader = BlockFullPage();
  paymentStore.isLoading = true;

  try {
    const response = await paymentStore.getTestPaymentData()

    product.value = response.data.product;
    authStore.agency.wallets = response.data.wallets;
    paymentStore.payment_methods = response.data.payment_methods;
    authStore.currencies = response.data.currencies;
    authStore.all_currencies = response.data.all_currencies;
    paymentStore.test_mode = true;


  } catch (e) {

    errorService.apiClientError(e);

  } finally {
    paymentStore.isLoading = false;
    UnBlocker(loader);
  }
};


/**
 * Make Payment Request
 * */

const makePayment = async () => {

  const data = {
    token: token,
    markup: 1,
    paymentData: paymentStore.getPaymentData
  };

  const loader = BlockFullPage();


  if (paymentStore.payment_method === 'credit') {

    try {
      const creditCardPayData = {
        payment_method: paymentStore.selected_payment_method.token,
        cardnumber: paymentStore.credit_card.card_number,
        name: paymentStore.credit_card.cardholder_name,
        expiration: paymentStore.credit_card.expiration,
        secure: paymentStore.credit_card.security_code,
        price: "1",
        bin_scheme: 'noname',
        currency: 'TL',
      };
      const response = await paymentStore.creditCardPay(creditCardPayData);

      const customToken = response.data.return_data;


      const responseCheckout = await paymentStore.creditCardPayCheckout(customToken)
      const htmlPreview = responseCheckout.data;

      paymentHtmlPreviewModalRef.value.openModal(htmlPreview);

    } catch (e) {

      console.log("error:", e);

    } finally {
      UnBlocker(loader);
    }

  }

}


onMounted(() => {

  getTestPaymentData();

});

</script>
