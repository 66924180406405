import Pusher from 'pusher-js';
import {useAuthStore} from '@/stores/authStore';
import eventBus from '@/core/services/event.bus.service';
import {isProduction} from "@/core/services/general.service";


let hotelSearchChannel = null;

const subscribe = (event, callback) => {
    if (hotelSearchChannel) {
        hotelSearchChannel.bind(event, callback);
    }
};

const unsubscribe = (event) => {
    if (hotelSearchChannel) {
        hotelSearchChannel.unbind(event);
    }
};

const initPusher = () => {
    const authStore = useAuthStore();

    if (!authStore.user) return;

    Pusher.logToConsole = !isProduction.value;

    const pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, {
        cluster: process.env.VUE_APP_PUSHER_CLUSTER,
        encrypted: true,
    });

    if (hotelSearchChannel) {
        hotelSearchChannel.unbind_all();
        hotelSearchChannel.unsubscribe();
    }

    hotelSearchChannel = pusher.subscribe(authStore.user.id.toString());

    // console.log(`Pusher Initialized for User ID: ${authStore.user.id}`);

    subscribe('hotel-search-channel', (data) => {
        // console.log("Hotel Search Data:", data);
        eventBus.emit('hotel-search-update', data);
    });
};

export default {
    subscribe,
    unsubscribe,
    initPusher
};
