import {defineStore} from "pinia";
import {systemConfigurationService} from "@/core/api/system-configuration.api";
import {BlockFullPage, ToastMessage, UnBlocker} from "@/core/services/general.service";
import {errorService} from "@/core/services/error.service";
import {hotelService} from "@/core/api/packages/hotel.api";
import {useAuthStore} from "@/stores/authStore";
import {useAgencyStore} from "@/stores/agencyStore";
import {reactive} from "vue";
import {useRouter} from "vue-router";
import Swal from "sweetalert2";


export const useHotelStore = defineStore('hotelStore', {
    state: () => ({
        can_filter: false,
        first_filter: true,
        searched_locations: [],
        search_loading: false,
        search_loading_from_route: false,
        searching_text: null,
        search_params: {
            location: null,
            location_type: null,
            location_code: null,
            location_preview_text: null,
            nationality: null,
            first_date: null,
            last_date: null,
        },
        nationalities: [],
        date_diff_days: 1,
        customers: {
            rooms: [
                {
                    adults: 2,
                    children: [],
                }
            ]
        },
        searched_hotels: [],
        searched_results: {},
        register_response: {},
        is_hotels_loading: false,
        hotels_map_visible: false,
        filters: reactive({
            loading: false,
            lazy: {
                load_count: 0,
                automatic_loading: true,
            },
            hotel_codes: [],
            star_rates: [
                {id: 1, value: false},
                {id: 2, value: false},
                {id: 3, value: false},
                {id: 4, value: false},
                {id: 5, value: false}
            ],
            price_range: {
                min_value: 0,
                max_value: 0,
                refresh_price: false,
            },
            keyword: null,
            rate_class: {
                NRF: false,
                NOR: false,
            },
            board_types: [],
            applied_markup: 0,
            sort_by: 'priceAscending',
        }),
        max_markup: null,
        current_markup: null,
    }),
    getters: {},
    actions: {
        /**
         * Searches for hotel locations
         *
         * @param {number} search search term
         *
         * @return {Promise<boolean>}
         * */
        async searchLocations(search) {
            return await hotelService.searchLocations(search)
                // .then(response => response.data.agency)
                .then(response => {
                    this.searched_locations = response.data.locations;
                    return true;
                })
                .catch(error => {
                    errorService.apiClientError(error);
                    return false;
                });
        },

        async getNationalities() {

            const loader = BlockFullPage();

            return await hotelService.getNationalities()
                // .then(response => response.data.agency)
                .then(response => {
                    this.nationalities = response.data.nationalities;

                    const authStore = useAuthStore();
                    authStore.currencies = response.data.currencies;
                    authStore.initCurrency();
                    this.max_markup = parseFloat(response.data.hotel_markup);

                    const storedCurrentMarkup = localStorage.getItem('hotelCurrentMarkup');
                    this.current_markup = storedCurrentMarkup ?? 0;


                    return true;
                })
                .catch(error => {
                    errorService.apiClientError(error);
                    return false;
                }).finally(() => {
                    setTimeout(() => UnBlocker(loader), 850);
                });
        },

        locationDisplayText(location) {

            let locationDisplayText = "";
            if (location?.type === 'location')
                locationDisplayText = `${location?.NAME} / ${location?.country_name}`;
            else
                locationDisplayText = `${location?.NAME} / ${location?.city} / ${location?.country_name}`;

            return locationDisplayText;
        },

        async registerSearchHotels(errorMode = true, router = null) {

            this.filters.lazy = {
                load_count: 0,
                can_load_more: true,
                automatic_loading: true,
            };


            if (this.search_params.location === null) {
                if (errorMode)
                    ToastMessage('error', 'Please select a location!', 'Error!');
                return false;
            }

            if (this.search_params.nationality === null) {
                if (errorMode)
                    ToastMessage('error', 'Please select a nationality!', 'Error!');
                return false;
            }

            if (router) {
                await router.push({
                    name: 'search', params: {
                        location: this.search_params.location ?? '-',
                        locationType: this.search_params.location_type,
                        locationCode: this.search_params.location_code ?? '-',
                        locationPreviewText: encodeURIComponent(this.search_params.location_preview_text),
                        nationality: this.search_params.nationality ?? '-',
                        firstDate: this.search_params.first_date ?? '-',
                        lastDate: this.search_params.last_date ?? '-',
                        customers: encodeURIComponent(JSON.stringify(this.customers)),
                        custom: Math.random(1, 12),
                    }
                });
                return;
            }


            this.is_hotels_loading = true;
            this.can_filter = true;

            let searchParams = this.search_params;
            let customers = this.customers;
            const storedMarkup = localStorage.getItem('hotelCurrentMarkup');
            const markup = (storedMarkup && storedMarkup <= this.max_markup)
                ? storedMarkup
                : 0;

            // console.log("applied markup:", markup);

            let current_markup = {markup: markup};

            let data = {...searchParams, ...customers, ...current_markup};
            return await hotelService.registerSearchHotels(data)
                // .then(response => response.data.agency)
                .then(async response => {

                    this.register_response = response.data;
                    this.first_filter = true;
                    return true;

                    this.resetFilters();
                    this.searched_hotels = response.data.results.hotels;
                    this.searched_results = response.data.results;
                    this.filters.applied_markup = response.data.results.markup;
                    this.filters.price_range.min_value = this.searched_results.min_min_price;
                    this.filters.price_range.max_value = this.searched_results.max_min_price;
                    this.filters.price_range.refresh_price = true;

                    setTimeout(() => this.can_filter = true, 1750);

                    setTimeout(() => {
                        window.scroll({
                            top: 600,
                            behavior: 'smooth'
                        })
                    }, 350);


                    return true;
                })
                .catch(error => {
                    this.searched_hotels = [];
                    this.searched_results = {};
                    errorService.apiClientError(error);
                    return false;
                }).finally(() => {
                    // this.is_hotels_loading = false;
                    setTimeout(() => this.search_loading_from_route = false, 60);
                });
        },

        getHotelsCoordinates() {
            const data = this.searched_hotels.map(hotel => ({
                lat: parseFloat(hotel.latitude),
                lng: parseFloat(hotel.longitude)
            }));
            return data;
        },

        roomCountChange(roomCount) {
            const currentRooms = this.customers.rooms.length;

            if (roomCount > currentRooms) {
                for (let i = currentRooms; i < roomCount; i++) {
                    this.customers.rooms.push({
                        adults: 2,
                        children: [],
                    });
                }
            } else if (roomCount < currentRooms) {
                this.customers.rooms.splice(roomCount);
            }
        },

        changeAdultsCount(count, index) {
            if (index >= 0 && index < this.customers.rooms.length) {
                this.customers.rooms[index].adults = count;
            } else {
                console.error('Invalid room index');
            }
        },

        changeChildCount(count, index) {
            if (index >= 0 && index < this.customers.rooms.length) {
                let currentChildren = this.customers.rooms[index].children;

                if (count > currentChildren.length) {
                    for (let i = currentChildren.length; i < count; i++) {
                        currentChildren.push(1);
                    }
                } else if (count < currentChildren.length) {
                    currentChildren = currentChildren.slice(0, count);
                }

                this.customers.rooms[index].children = currentChildren;
            } else {
                console.error('Invalid room index');
            }
        },

        changeChildAge(age, roomIndex, childIndex) {
            this.customers.rooms[roomIndex].children[childIndex] = age;
        },

        resetCustomers() {
            this.customers.rooms = [
                {
                    adults: 2,
                    children: [],
                }
            ]
        },

        deleteRoom(roomIndex) {
            if (roomIndex >= 0 && roomIndex < this.customers.rooms.length) {
                this.customers.rooms.splice(roomIndex, 1);
            } else {
                console.error('Invalid room index');
            }

            if (this.customers.rooms.length === 0)
                this.resetCustomers();
        },

        async lazyLoadHotels() {

            this.filters.lazy.automatic_loading = false;
            this.filters.loading = true;
            this.can_filter = false;

            const data = {
                ...this.filters,
                cache_key: this.searched_results.cache_key,
                markup: this.current_markup,
            }

            return await hotelService.filterHotels(data)
                // .then(response => response.data.agency)
                .then(response => {

                    this.searched_hotels = response.data.results.hotels;
                    this.searched_results = response.data.results;
                    if (this.filters.lazy.load_count < 3) {
                        this.filters.lazy.automatic_loading = true;
                    }
                    return true;
                })
                .catch(error => {
                    // this.searched_hotels = [];
                    // this.searched_results = {};
                    errorService.apiClientError(error);
                    return false;
                }).finally(() => {
                    setTimeout(() => this.filters.loading = false, 1250)
                    setTimeout(() => this.can_filter = true, 1250)
                });
        },

        async filterHotels(resetLazyLoad = true) {


            // console.log("can  filter:", this.can_filter, "loading status:", this.filters.loading)
            // console.trace("filter called from trace");


            if (!this.can_filter || this.filters.loading) {
                console.error("filter prevented on purpose!");
                return;
            }

            this.can_filter = false;

            if (resetLazyLoad) {
                this.is_hotels_loading = true;
                this.filters.lazy.load_count = 0;
                this.filters.lazy.automatic_loading = true;
            }

            const data = {
                ...this.filters,
                cache_key: this.searched_results.cache_key,
                markup: this.current_markup,
            }

            return await hotelService.filterHotels(data)
                // .then(response => response.data.agency)
                .then(response => {
                    this.searched_hotels = response.data.results.hotels;
                    this.searched_results = response.data.results;
                    this.filters.applied_markup = response.data.results.applied_markup;

                    if (this.first_filter) {
                        setTimeout(() => {
                            window.scroll({
                                top: 600,
                                behavior: 'smooth'
                            })
                        }, 350);
                        this.first_filter = false;
                    }

                    return true;
                })
                .catch(error => {
                    // this.searched_hotels = [];
                    // this.searched_results = {};
                    errorService.apiClientError(error);
                    return false;
                }).finally(() => {
                    this.is_hotels_loading = false;

                    setTimeout(() => this.can_filter = true, 1000);
                });
        },

        resetFilters() {
            // console.log("Reset Filters Worked!");
            this.filters = {
                loading: false,
                lazy: {
                    load_count: 0,
                    automatic_loading: true,
                },
                hotel_codes: [],
                star_rates: [
                    {id: 1, value: false},
                    {id: 2, value: false},
                    {id: 3, value: false},
                    {id: 4, value: false},
                    {id: 5, value: false}
                ],
                price_range: {
                    min_value: 0,
                    max_value: 0,
                },
                keyword: null,
                rate_class: {
                    NRF: false,
                    NOR: false,
                },
                board_types: [],
                applied_markup: 0,
                sort_by: 'priceAscending',
            };
        },

        clearAllSearchPage() {
            this.searched_locations = [];
            this.searching_text = null;
            this.search_params.location = null;
            this.search_params.location_preview_text = null;
            this.search_params.location_type = null;
            this.search_params.nationality = null;
            this.hotels_map_visible = false;
            this.searched_hotels = [];
            this.searched_results = {};
            this.resetCustomers();
        },

        async makeInvoice(data) {

            const loader = BlockFullPage();
            const router = useRouter();
            try {
                const response = await hotelService.makeInvoice(data);


                const token = response.data.token;
                const url = `${window.location.origin}/Packages/Hotel/Reservation/${token}`;
                window.open(url, '_blank');


                // await router.push({name: 'reservation', params: {token: response.data.token}});

            } catch (e) {
                console.log("values:", e?.response?.status, e?.response?.data?.error);
                if (e?.response?.status === 400 && e?.response?.data?.error === 'not available') {
                    this.filterHotels(false);
                }
                errorService.apiClientError(e);
            } finally {
                UnBlocker(loader);
            }
        },


        /**
         * Hotel Reservation
         * */

        async getReservationData(token) {
            return await hotelService.getReservationPageInfo(token);
        },

        async proceedToPayment(data) {
            return await hotelService.proceedToPayment(data);
        },

        async getPaymentData(token) {
            return await hotelService.getPaymentData(token);
        },

        async makePayment(data) {
            return await hotelService.makePayment(data);
        },

        async handleUiNotAvailable(router) {

            const message = 'The room you selected is no longer available, please continue using a different room.';

            Swal.fire({
                icon: 'error',
                title: 'Room Not Available',
                text: message,
                showCancelButton: true,
                confirmButtonText: 'Make Another Search',
                cancelButtonText: 'Close Page',
                customClass: {
                    confirmButton: 'me-4 btn btn-primary',
                    cancelButton: 'btn btn-danger'
                },
                buttonsStyling: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
            }).then(async (result) => {
                if (result.isConfirmed) {
                    await router.push({name: 'search'});
                } else {
                    window.close();
                }
            });
        },

        async getSuccessfulReservationData(token) {
            return await hotelService.getSuccessfulReservationData(token);
        },

    }
});