import axios, { AxiosInstance } from 'axios';
import router from "@/router";
import { ToastMessage } from "@/core/services/general.service";
import { useAuthStore } from "@/stores/authStore";

export function setupInterceptors(axiosInstance) {
    // Request Interceptor
    axiosInstance.interceptors.request.use((config) => {
        config.headers['Currency'] = localStorage.getItem('currency') ?? null;
        config.headers['Accept-Language'] = localStorage.getItem('currentLanguage') ?? null;
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    // Response Interceptor
    axiosInstance.interceptors.response.use(
        response => response,
        error => {
            const authStore = useAuthStore();

            if (error.response) {
                const responseData = error.response.data;

                if (error.response.status === 401) {
                    if (responseData.error === "token_has_expired") {
                        ToastMessage('error', 'Token has expired! You have to login again.', 'Error');
                        authStore.logout();
                    } else if (responseData.message === "Unauthenticated.") {
                        ToastMessage('error', 'Please login first!', 'Error');
                        authStore.logout();
                    }
                } else if (error.response.status === 403 && responseData.message === "PermissionDenied") {
                    const previousRoute = router.options.history.state.back;
                    if (previousRoute) {
                        router.push(previousRoute);
                    } else {
                        router.push('/');
                    }
                }
            }

            return Promise.reject(error);
        }
    );
}

// Base API Client
export const apiClientFullURL = process.env.VUE_APP_API_URL + "/api";
export const apiClient = axios.create({
    baseURL: apiClientFullURL,
    headers: {
        'Content-type': 'application/json; charset=utf-8',
        'Accept': 'application/json',
    },
    timeout: 5000000,
});

// Secure API Client
export const secureClientFullURL = process.env.VUE_APP_SECURE_URL + "/api";
export const secureClient = axios.create({
    baseURL: secureClientFullURL,
    headers: {
        'Content-type': 'application/json; charset=utf-8',
        'Accept': 'application/json',
    },
    timeout: 5000000,
});

// Apply interceptors to both clients
setupInterceptors(apiClient);
setupInterceptors(secureClient);