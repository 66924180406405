import {apiClient, apiClientFullURL, secureClient, secureClientFullURL} from "@/core/common/http-common";
import {isProduction} from "@/core/services/general.service";

export default class HotelService {
    const
    base_url = apiClientFullURL;

    /**
     * Hotel Reservation Search
     * */

    async searchLocations(search) {
        return await apiClient.get(`/Packages/Hotels/Transactions/SearchLocation?param=${search}`);
    }

    async getNationalities(search) {
        return await apiClient.get(`/Packages/Hotels/Transactions/GetNationalities`);
    }

    async registerSearchHotels(params) {
        const client = isProduction.value ? secureClient : apiClient;

        return await secureClient.post(`/Packages/Hotels/Transactions/RegisterSearchHotels`, params);
    }

    async filterHotels(filters) {
        return await apiClient.post(`/Packages/Hotels/Transactions/FilterHotels`, filters);
    }

    async makeInvoice(data) {
        return await apiClient.post(`/Packages/Hotels/Transactions/MakeInvoice`, data);
    }

    /**
     * Hotel Reservation
     * */

    async getReservationPageInfo(token) {
        return await apiClient.get(`/Packages/Hotels/Transactions/GetReservationData?token=${token}`);
    }

    async proceedToPayment(data) {
        return await apiClient.post(`/Packages/Hotels/Transactions/ProceedToPayment`, data);
    }

    async getPaymentData(token) {
        return await apiClient.get(`/Packages/Hotels/Transactions/GetPaymentData?token=${token}`);
    }

    async makePayment(data) {
        return await apiClient.post(`/Packages/Hotels/Transactions/MakePayment`, data);
    }

    selectCustomerDataLoad() {
        return this.base_url + "/Packages/Hotels/Transactions/GetCustomers";
    }

    async getSuccessfulReservationData(token) {
        return await apiClient.get(`/Packages/Hotels/Transactions/GetSuccessfulReservationData?token=${token}`);
    }
}

export const hotelService = new HotelService();