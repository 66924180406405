<template>
  <div id="ModalPaymentHtmlPreview" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Payment Modal</h4>
          <button
              class="btn-close"
              type="button"
              aria-label="Close"
              @click="closeModal"
          ></button>
        </div>
        <div id="ModalBodyExample" class="modal-body">
          <iframe
              ref="iframeRef"
              :key="iframeKey"
              :src="iframeSrc"
              frameborder="0"
              style="width: 100%; height: 500px;"
          ></iframe>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="closeModal">
            {{ $translate('common.close') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {onMounted, ref, onUnmounted} from 'vue';
import {Modal} from 'bootstrap';
import {$translate} from "@/core/services/translator.service";
import {ConfirmWithSwal, SwalMessage, ToastMessage} from "@/core/services/general.service";
import {usePaymentStore} from "@/stores/paymentStore";

const modalUserDetails = ref(null);
const iframeRef = ref(null);
const iframeSrc = ref('about:blank'); // Başlangıçta boş içerik
const iframeKey = ref(0);
const paymentStore = usePaymentStore();
const emit = defineEmits(['paymentSuccessfully']);

onMounted(() => {
  modalUserDetails.value = new Modal(document.getElementById('ModalPaymentHtmlPreview'), {
    backdrop: true,
    keyboard: false,
  });
  window.addEventListener('message', handlePaymentMessage);
});

onUnmounted(() => {
  window.removeEventListener('message', handlePaymentMessage);
});

const openModal = (htmlContent) => {

  if (iframeRef.value) {
    const iframeDoc = iframeRef.value.contentDocument || iframeRef.value.contentWindow.document;
    if (iframeDoc) {
      iframeDoc.open();
      iframeDoc.write(htmlContent);
      iframeDoc.close();
    }
  }
  modalUserDetails.value.show();
};

const closeModal = async (confirmClosing = true) => {

  if (confirmClosing) {
    let result = await ConfirmWithSwal(
        'warning',
        "Please don't close this window , otherwise your payment will be interrupted and will take more time to process. Are you sure you want to turn it off?",
        'Warning',
        "Yes, I'm sure",
        "No, I'm not sure"
    )

    if (!result)
      return false;
  }

  iframeSrc.value = 'about:blank';
  modalUserDetails.value.hide();
  iframeKey.value++;
};

const handlePaymentMessage = (event) => {
  try {
    if (typeof event.data === "string" && event.data.includes("__pay_creditcard__")) {
      const json = JSON.parse(event.data);
      const return_method = json['__pay_creditcard__'];
      const message = json[0]?.message || '';

      if (message.includes('Payment Confirmed') || message.includes('payment confirmed')) {
        /**
         * here works when test mode is active.
         * */

        console.log('Payment Successful!', json[0]);

        closeModal(false);

        SwalMessage('success', 'Payment is successful! In order to check the full response, see the developer console!', 'Payment Successfully!');

        // alert('Payment Successful!');
      } else if (return_method === 'error' || return_method === 'fail') {
        const errorMessage = message || 'Payment Failed';
        ToastMessage('error', errorMessage, 'Payment Failed');
        paymentStore.paymentFailed = true;
        paymentStore.paymentFailedMessage = "Payment Failed: " + errorMessage;
        closeModal(false);
      } else if (return_method === 'pay' || return_method === 'success') {
        closeModal(false);
        emit('paymentSuccessfully', json[0]);
        //alert('Payment Successful!');
      }
    }
  } catch (err) {
    console.error('An error occurred while processing payment: ', err);
    ToastMessage('error', 'An error occurred while processing payment', 'Payment Error');
  }
};


defineExpose({
  openModal,
  closeModal,
});
</script>
