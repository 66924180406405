<template>
  <div>

    <div class="row mt-3 d-flex align-items-center justify-content-center">
      <div class="col-md-4">
        <label for="wallet">Wallet:</label>
        <select v-model="selectedWalletId" name="" id="wallet" class="form-control">
          <option value="">Select</option>
          <option v-for="wallet in availableWallets" :value="wallet.id">{{ wallet.currency_code }}</option>
        </select>
      </div>
    </div>

    <div class="row mt-2 d-flex align-items-center justify-content-center">
      <div class="col-md-6">
        <WalletWidget v-if="paymentStore.selected_wallet" :wallet="paymentStore.selected_wallet"/>
      </div>
    </div>

    <div v-if="paymentStore.selected_wallet" class="row mt-2 d-flex align-items-center justify-content-center">
      <div v-if="false" class="col-md-12 text-center">
        <span class="text-primary">
          You will be charged
          <b>{{ priceFormatter(amountToBePaid, paymentStore.selected_wallet.currency_code) }}</b>
          to your <b>{{ paymentStore.selected_wallet.currency_code }}</b> account.
        </span>
      </div>
      <div class="col-md-12 text-center">
        <span v-if="paymentStore.selected_wallet.balance >= amountToBePaid" class="text-success">
          Available
        </span>
        <span v-else class="text-danger">Your balance is insufficient.</span>
      </div>
    </div>

  </div>
</template>

<script setup>
import {usePaymentStore} from "@/stores/paymentStore";
import {computed, ref, watch} from "vue";
import {useAuthStore} from "@/stores/authStore";
import {changeSystemCurrency, currencyExchange, priceFormatter} from "@/core/services/general.service";
import WalletWidget from "@/components/WalletWidget.vue";

const paymentStore = usePaymentStore();
const authStore = useAuthStore();

const selectedWalletId = ref("");


watch(selectedWalletId, (newVal) => {
  if (newVal) {
    changeSystemCurrency(paymentStore.selected_wallet.currency_code);
  }
});

paymentStore.selected_wallet = computed(() => {
  if (selectedWalletId.value)
    return availableWallets.value.find(w => w.id === parseInt(selectedWalletId.value))

  return null;
});

const availableWallets = computed(() => {
  return authStore.agency.wallets;
});

const amountToBePaid = computed(() => {
  return currencyExchange(
      paymentStore.amount_to_be_paid,
      paymentStore.original_currency,
      paymentStore.selected_wallet.currency_code).converted_price
});

</script>

<style scoped>

</style>