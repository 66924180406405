import {apiClient} from "@/core/common/http-common";

export default class PaymentService {


    async creditCardPay(url, data) {
        return await apiClient.post(url, data);
    }


    async creditCardTestPay(data) {
        return await apiClient.post(`/_testpay`, data);
    }

    async creditCardPayCheckout(token) {
        return await apiClient.post(`/checkout/preview?req_data=${token}`);
    }

    async getTestPaymentData() {
        return await apiClient.get(`/_testpay/get-test-payment-data`);
    }

}

export const paymentService = new PaymentService();