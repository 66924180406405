<script setup>
import {priceFormatter} from "@/core/services/general.service";
import {computed} from "vue";

const props = defineProps({
  price: {
    type: Number,
    required: true
  }
});


const totalPrice = computed(() => {
  return priceFormatter(props.price, 'TL')
});

</script>

<template>
  <div class="card">
    <div class="card-header p-3">
      <h5><i class="fa fa-product-hunt text-dark mx-2"></i> Products</h5>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-12 ps-0 pe-0 mb-2">
          <h6>
            <b>Some Products: </b> Product <small
              class="text-muted">(Some Product Details)</small></h6>
        </div>

        <div class="divider"></div>


        <div class="col-md-12 mt-4 d-flex align-items-center justify-content-between">
          <span class="price-label">Total Price: </span>
          <span class="just-price">{{ totalPrice }}</span>
        </div>

      </div>
    </div>
  </div>
</template>


<style scoped>

.price-label {
  font-weight: bold;
  font-size: 1.3rem;
}

.just-price {
  font-weight: bold;
  font-size: 1.6rem;
}
</style>