<template>
  <div>
    <StepHeader
        step-number="1"
        step-title="Payment Method"
        step-description="Please choose your payment method."
    />


    <div class="payment-methods-container">

      <div v-if="paymentStore.isLoading" class="d-flex align-items-center justify-content-center mb-4">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>

      <div
          v-else
          class="payment-method-item"
          v-for="(paymentMethod, index) in paymentStore.payment_methods"
          :key="index"
          @click="selectPaymentMethod(index)"
          :class="{ 'selected-method': selectedMethod === index }"
      >
        <img
            :src="paymentMethod.image"
            alt="payment method"
            class="payment-method-img"
        />
        <div class="payment-method-name">
          <template v-if="isProduction">
            {{ paymentMethod.name.toLowerCase().includes('3d') ? '3D Secure' : '' }}
          </template>
          <template v-else>
            <div class="method-name">{{ paymentMethod.name }}</div>
            <div class="method-slug">({{ paymentMethod.slug }})</div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref, computed, watch} from 'vue';
import {usePaymentStore} from '@/stores/paymentStore';
import StepHeader from '@/components/General/StepHeader.vue';
import {isProduction} from "@/core/services/general.service";

const paymentStore = usePaymentStore();
const selectedMethod = ref(null);


watch(() => paymentStore.selected_payment_method, (newVal, oldVal) => {
  if (!paymentStore.selected_payment_method)
    selectedMethod.value = null;
});

const selectPaymentMethod = (index) => {
  selectedMethod.value = index;
  paymentStore.selected_payment_method = paymentStore.payment_methods[index];

  if (paymentStore.test_mode) return;

  paymentStore.transaction.addCreditCardCommissionRateToProduct(
      paymentStore.selected_payment_method.commission_rate
  );

};
</script>

<style scoped>
.step-header {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  margin-bottom: 1.5rem;
  padding: 1rem;
  background-color: #f8f9fa;
  border-radius: 8px;
}

.step-number {
  background-color: #007bff;
  color: white;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  flex-shrink: 0;
}

.step-content {
  flex: 1;
}

.step-title {
  color: #007bff;
  margin: 0;
  font-size: 1.25rem;
  font-weight: 600;
}

.step-description {
  color: #6c757d;
  margin: 0.5rem 0 0 0;
  font-size: 0.95rem;
}

.payment-methods-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  align-items: center;
  padding: 15px;
  cursor: pointer;
}

.payment-method-item {
  flex: 0 0 auto;
  padding: 15px;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.payment-method-item:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.payment-method-img {
  width: 120px;
  height: auto;
  cursor: pointer;
  object-fit: contain;
}

.payment-method-name {
  text-align: center;
  margin-top: 8px;
  font-size: 0.9rem;
  color: #666;
}

.method-name {
  font-weight: 500;
  margin-bottom: 4px;
}

.method-slug {
  font-size: 0.8rem;
  color: #888;
}

.selected-method {
  border: 2px solid #4caf50;
  background-color: rgba(76, 175, 80, 0.05);
}
</style>
