<template>
  <div>

    <div class="row">
      <div class="col-md-12 d-flex align-items-center justify-content-center my-2">
        <div style="display: none;" class="dx-field">
          <span :class="setTextClass('agency')">Agency Payment</span>
          <DxSwitch class="mx-3" @value-changed="handleChangeWhoPays" value="true" :key="switchKey"/>
          <span :class="setTextClass('customer')">Customer Payment</span>
        </div>
      </div>
    </div>

    <div class="row d-flex my-2">
      <div class="col-md-12">
        <CardPaymentMethods/>
      </div>
      <div class="col-md-12">

        <CardInfoFrom/>

      </div>
    </div>

  </div>
</template>

<script setup>
import CardInfoFrom from "@/components/PaymentInformation/includes/CardPayment/includes/CardInfoFrom.vue";
import {ref, watch} from 'vue';
import {DxSwitch} from 'devextreme-vue/switch';
import {usePaymentStore} from "@/stores/paymentStore";
import CardPaymentMethods from "@/components/PaymentInformation/includes/CardPayment/includes/CardPaymentMethods.vue";

const paymentStore = usePaymentStore();

const handleChangeWhoPays = (e) => {
  paymentStore.who_pays = e.value ? 'customer' : 'agency'

  paymentStore.amount_to_be_paid = paymentStore.who_pays === 'customer' ? paymentStore.price : paymentStore.original_price;

}

const switchKey = ref(0);

watch(() => paymentStore.payment_method, (newVal, oldVal) => {
  switchKey.value++;
});


const setTextClass = (who) => {
  if (paymentStore.who_pays === who) {
    return 'text-primary';
  } else {
    return 'text-muted';
  }
}


</script>

<style scoped>

</style>