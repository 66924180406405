import {defineStore} from 'pinia';
import {hotelService} from "@/core/api/packages/hotel.api";
import {paymentService} from "@/core/api/payment.api";

export const usePaymentStore = defineStore('paymentStore', {
    state: () => ({
        test_mode: false,
        payment_method: 'credit',
        amount_to_be_paid: null,
        original_currency: 'USD',
        original_price: null,
        price: null,
        credit_card_commission: 0,
        credit_card_commission_rate: 0,
        who_pays: 'customer',
        credit_card: {
            cardholder_name: 0,
            card_number: 0,
            expiration: 0,
            security_code: 0,
        },
        payment_methods: [],
        selected_payment_method: null,
        selected_wallet: null,
        paymentFailed: false,
        paymentFailedMessage: "",
        transaction: {
            addCreditCardCommissionRateToProduct: null,
            removeCreditCardCommissionRateFromProduct: null,
        },
        paymentResultData: null,
        isLoading: false,
    }),
    getters: {
        getPaymentData(state) {
            const returnData = {
                paymentMethod: state.payment_method,
                whoPays: state.who_pays,
            };

            const creditPaymentData = {
                creditCard: state.credit_card,
                paymentMethod: state.selected_payment_method,
            };

            const currentPaymentData = {
                selectedWallet: state.selected_wallet,
            };

            if (state.payment_method === 'credit')
                return {
                    ...returnData,
                    ...creditPaymentData,
                };
            else {
                return {
                    ...returnData,
                    ...currentPaymentData,
                };
            }
        },
    },
    actions: {
        setPrice(price, original_price, currency, amount_to_be_paid = false) {
            this.price = price;
            this.original_price = original_price;
            this.original_currency = currency;

            if (amount_to_be_paid)
                this.amount_to_be_paid = price;

            this.determineAmountToBePaid();
        },

        determineAmountToBePaid() {
            if (this.payment_method === 'credit') {
                this.amount_to_be_paid =
                    this.who_pays === 'customer' ? this.price : this.original_price;
            } else {
                this.amount_to_be_paid = this.original_price;
            }
        },

        checkCardInformation() {
            if (
                this.credit_card.cardholder_name
                && this.credit_card.card_number
                && this.credit_card.expiration
                && this.credit_card.security_code
            ) return true;

            return false;
        },

        async creditCardPay(data, url = null) {
            if (this.test_mode)
                return await paymentService.creditCardTestPay(data);

            return await paymentService.creditCardPay(url, data);
        },

        async creditCardPayCheckout(token) {
            return await paymentService.creditCardPayCheckout(token);
        },

        async getTestPaymentData() {
            return await paymentService.getTestPaymentData();
        },


    },
});
