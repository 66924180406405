<template>
  <div>
    <div class="card">
      <div class="card-header p-3">
        <h5><i class="fa fa-credit-card-alt text-dark mx-2"></i> Payment Information</h5>
      </div>
      <div class="card-body p-0">
        <div class="card mt-3 mb-0">
          <div class="card-body p-0">
            <ul class="nav nav-tabs" id="icon-tab" role="tablist">
              <li class="nav-item">
                <a class="nav-link"
                   v-if="creditPaymentEnabled"
                   :class="activeTab === 'credit' ? 'active' : ''"
                   @click="setActive('credit')"
                   href="javascript:void(0)">
                  <i class="fa fa-credit-card"></i>
                  Credit Card / Debit Card
                </a>
              </li>

              <li v-if="availableWallets.length && currentPaymentEnabled" class="nav-item">
                <a class="nav-link"
                   :class="activeTab === 'current' ? 'active' : ''"
                   @click="setActive('current')"
                   href="javascript:void(0)">
                  <i class="icofont icofont-wallet"></i>
                  Current Payment
                </a>
              </li>
            </ul>

            <div class="tab-content p-2" id="icon-tabContent">

              <div class="tab-pane fade" :class="activeTab === 'credit' ? 'show active' : ''">
                <CardPayment/>
              </div>

              <div class="tab-pane fade"
                   :class="activeTab === 'current' ? 'show active' : ''">
                <CurrentPayment/>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <MakePayment @make-payment="emit('make-payment')"/>
  </div>
</template>

<script setup>
import CardPayment from "@/components/PaymentInformation/includes/CardPayment/CardPayment.vue";
import CurrentPayment from "@/components/PaymentInformation/includes/CurrentPayment/CurrentPayment.vue";
import MakePayment from "@/components/PaymentInformation/includes/MakePayment/MakePayment.vue";
import {usePaymentStore} from "@/stores/paymentStore";
import {computed, onMounted} from "vue";
import {useAuthStore} from "@/stores/authStore";

const props = defineProps({
  creditPaymentEnabled: {
    type: Boolean,
    default: true
  },
  currentPaymentEnabled: {
    type: Boolean,
    default: true
  },
});

const emit = defineEmits(['make-payment'])

const authStore = useAuthStore();
const paymentStore = usePaymentStore();


const availableWallets = computed(() => {
  return authStore.agency.wallets;
});


const activeTab = computed(() => paymentStore.payment_method);

const setActive = (method) => {
  paymentStore.payment_method = method;

  paymentStore.who_pays = method === 'current' ? 'agency' : 'customer';

  if (paymentStore.test_mode) return;

  paymentStore.transaction.removeCreditCardCommissionRateFromProduct();
  paymentStore.credit_card_commission = 0;
  paymentStore.credit_card_commission_rate = 0;
  paymentStore.selected_payment_method = null;

  paymentStore.determineAmountToBePaid();
}

onMounted(() => {
  paymentStore.amount_to_be_paid = paymentStore.price;

  if (paymentStore.payment_method === 'current') setActive('current');
});

</script>

<style scoped></style>
